import * as React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { CarouselHeader } from './header';
import { CarouselRow } from './row';
import { TaskOnTable } from './task-table';
import { TaskTrackingOnTable } from './task-tracking';
import TaskScroller from './task-scroller';
import { selectUsers } from '~/redux/selectors/usersSelectors';
import { selectTasksShift } from '~/redux/selectors/tasksSelectors';
import { selectDayDinners, selectDayTracking } from '~/redux/selectors/shiftsSelectors';
import { selectCurrentShifts } from '~/redux/selectors/shiftsSelectors';
import { selectCurrentUserId } from '~/redux/selectors/authSelectors';
import TaskDinner from '~/components/task-carousel/TaskDinner';
import { FORMAT_MOMENT } from '~/helpers/constants';
import CurrentTimeLine from '~/components/task-carousel/CurrentTimeLine';
import useShiftTime from '~/hooks/useShiftTime';
import { selectDepartmentIds } from '~/redux/selectors/stateSelectors';
import containsArray from '~/helpers/containsArray';
import TaskShift from '~/components/task-carousel/TaskShift';

const TaskCarousel = () => {
  const usersList = useSelector(selectUsers);
  const userId = useSelector(selectCurrentUserId);
  const departmentIds = useSelector(selectDepartmentIds);
  const tasks = useSelector(selectTasksShift);
  const tracking = useSelector(selectDayTracking);
  const dinners = useSelector(selectDayDinners);
  const shifts = useSelector(selectCurrentShifts);
  const { beginTime, endTime } = useShiftTime();

  const trackingWithTime = React.useMemo(
    () => tracking.filter(track => track.begin_time || track.end_time),
    [tracking],
  );

  const hours = React.useMemo(() => {
    const result = [];

    const end = moment(endTime).startOf('hour');
    const now = moment(beginTime).startOf('hour');

    if (now.isAfter(end)) {
      end.add(1, 'days');
    }

    if (now.isSame(end)) {
      for (let i = 0; i < 25; i++) {
        result.push({ key: now.format(FORMAT_MOMENT.DHMM), time: now.format(FORMAT_MOMENT.HMM) });
        now.add(1, 'hour');
      }
    } else {
      end.add(1, 'hour');

      if (now.isSame(end)) {
        for (let i = 0; i < 25; i++) {
          result.push({ key: now.format(FORMAT_MOMENT.DHMM), time: now.format(FORMAT_MOMENT.HMM) });
          now.add(1, 'hour');
        }
      } else {
        while (!now.isSame(end)) {
          result.push({ key: now.format(FORMAT_MOMENT.DHMM), time: now.format(FORMAT_MOMENT.HMM) });
          now.add(1, 'hour');
        }
      }
    }
    return result;
  }, [beginTime, endTime]);

  let filteredShifts = shifts.filter(shift => {
    const shiftUser = usersList.find(user => user.id === shift.user_id);

    return shiftUser && containsArray(departmentIds, shiftUser?.departments_ids || []);
  });

  const myShift = filteredShifts.find(user => user?.user_id === userId);

  filteredShifts = filteredShifts.filter(user => user.user_id !== userId);

  if (myShift) filteredShifts.unshift(myShift);

  const userTasks = React.useMemo(() => {
    return tasks
      .map(task => {
        return {
          order: filteredShifts.findIndex(
            shift =>
              shift?.id === task?.shift_id || (shift?.user_id === task?.user_id && task.parent_id),
          ),
          start_date: task?.start_date,
          end_date: task?.end_date,
          duration: task?.duration,
          ...task,
        };
      })
      .filter(v => v.order !== -1);
  }, [tasks, filteredShifts]);

  const taskCarouselRef = React.useRef(null);

  return (
    <>
      <TaskScroller taskContainer={taskCarouselRef} users={filteredShifts} />
      <div className="task-carousel__wrapper" ref={taskCarouselRef}>
        <CarouselHeader shifts={filteredShifts} />
        <div style={{ position: 'relative', width: 'max-content' }}>
          {hours.map(({ key, time }) => (
            <CarouselRow key={key} time={time} users={filteredShifts} />
          ))}
          <div className="task-carousel__inner">
            {userTasks.map((task, idx) =>
              task.tracks?.length ? (
                task?.tracks?.map((track, key) => {
                  if (
                    track &&
                    (moment(track.start_time).isSame(moment(beginTime), 'dates') ||
                      moment(track.start_time).isSame(moment(endTime), 'dates') ||
                      moment(track.end_time).isSame(moment(beginTime), 'dates') ||
                      moment(track.end_time).isSame(moment(endTime), 'dates'))
                  ) {
                    return (
                      <TaskOnTable
                        users={filteredShifts}
                        task={task}
                        track={track}
                        key={`${task.id}-${idx}-${key}`}
                        {...task}
                        read_only={task.read_only}
                        taskCarouselRef={taskCarouselRef}
                      />
                    );
                  }
                })
              ) : (
                <>
                  {task?.begin_time ? (
                    <TaskOnTable
                      users={filteredShifts}
                      task={task}
                      key={`${task.id}-${idx}`}
                      {...task}
                      read_only={task.read_only}
                      taskCarouselRef={taskCarouselRef}
                    />
                  ) : null}
                </>
              ),
            )}
            <CurrentTimeLine />
            {dinners.map((dinner, idx) => (
              <TaskDinner key={idx} users={filteredShifts} dinner={dinner} />
            ))}
            {filteredShifts.map((shift, idx) => (
              <TaskShift key={idx} shift={shift} index={idx} shifts={filteredShifts} />
            ))}
            {trackingWithTime.map((track, idx) => (
              <TaskTrackingOnTable key={idx} users={filteredShifts} track={track} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskCarousel;
