import { IStore } from '~/redux/IStore';
import { createDraftSafeSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { FORMAT_MOMENT } from '~/helpers/constants';

const selectSelf = (state: IStore) => state;

export const selectDay = createDraftSafeSelector(selectSelf, state => state.state.day);

export const selectCurrentShift = createDraftSafeSelector(
  selectSelf,
  state => state.state.currentShift,
);

export const selectCurrentTask = createDraftSafeSelector(
  selectSelf,
  state => state.state.currentTask,
);

export const selectIsSelectedToday = createDraftSafeSelector(
  selectSelf,
  state => state.state.day === moment().format(FORMAT_MOMENT.DASH_YYYYMMDD),
);

export const selectIsMoreOrEqToday = createDraftSafeSelector(
  selectSelf,
  state => moment(state.state.day).unix() >= moment().startOf('day').unix(),
);

/** @deprecated **/
export const selectDepartmentId = createDraftSafeSelector(
  selectSelf,
  state => state.state.departmentId,
);

export const selectDepartmentIds = createDraftSafeSelector(
  selectSelf,
  state => state.state.departmentIds,
);

export const selectWorkShiftId = createDraftSafeSelector(
  selectSelf,
  state => state.state.workShiftId,
);

export const selectWorkShiftIds = createDraftSafeSelector(
  selectSelf,
  state => state.state.workShiftIds,
);

export const selectUserIdsToShow = createDraftSafeSelector(
  selectSelf,
  state => state.state.usersIdsToShow,
);

export const selectPopup = createDraftSafeSelector(selectSelf, state => state.popup);

export const selectAlert = createDraftSafeSelector(selectSelf, state => state.state.alert);
