import Centrifuge from 'centrifuge';
import Api, { GetCentrifugeResult } from 'sb_manufacturing_front_api';
import store from '~/redux/configureStore';
import moment from 'moment';
import { prepareShift } from '~/redux/modules/shiftsModule';
import { FORMAT_MOMENT } from '~/helpers/constants';
let envWS: string;

if (process.env.REACT_APP_HOST_WS) {
  envWS = process.env.REACT_APP_HOST_WS;
} else {
  throw new Error('REACT_APP_HOST_WS value not set');
}

const centrifuge = new Centrifuge(envWS);

const isPayloadValid = (type: any, payload: any[]) => {
  // CheckIds
  let isValid = true;
  isValid = payload.reduce((reducer: boolean, item: any) => reducer && 'id' in item, isValid);
  return isValid;
};

export async function centrifugeConnect(id: number, companyId: number) {
  console.log('centrifuge connect');

  if (!centrifuge.isConnected()) {
    const tokenCentrifuge = await centrifugeToken();

    if (!tokenCentrifuge) return;

    centrifuge.setToken(tokenCentrifuge);

    centrifuge.subscribe(`user_${id}_${companyId}`, function (message) {
      const { type, action, payload } = message.data;

      const userId = store.getState()?.auth?.user?.id;
      const currentShiftId = store.getState()?.state?.currentShift?.id;
      const currentTaskId = store.getState()?.state?.currentTask?.id;
      const day = store.getState()?.state?.day;

      if (['remove'].includes(action) || isPayloadValid(type, payload)) {
        store.dispatch({ type: `${type}/${action}`, payload: payload });
        if (type === 'tasksTimeless') {
          if (!['remove', 'clear'].includes(action)) {
            store.dispatch({
              type: `${type}/update`,
              payload: [
                {
                  ...payload[0],
                  end_date: moment(payload[0]?.end_date)
                    .subtract(1, 'days')
                    .format(FORMAT_MOMENT.YYYYMMDD_HHMMSS),
                },
              ],
            });
          }
          if (action === 'create') {
            payload.map((item: any) => {
              if (item.tasks.length) {
                item.tasks.map((task: any) => {
                  store.dispatch({
                    type: `tasks/add`,
                    payload: {
                      ...task,
                      start_date: item?.start_date,
                      end_date: moment(item?.end_date)
                        .subtract(1, 'days')
                        .format(FORMAT_MOMENT.YYYYMMDD_HHMMSS),
                      duration: item.duration,
                    },
                  });
                });
              }
            });
          }
          if (action === 'update') {
            payload.map((item: any) => {
              if (item.tasks.length) {
                item.tasks.map((task: any) => {
                  store.dispatch({
                    type: `tasks/add`,
                    payload: {
                      ...task,
                      start_date: item?.start_date,
                      end_date: moment(item?.end_date)
                        .subtract(1, 'days')
                        .format(FORMAT_MOMENT.YYYYMMDD_HHMMSS),
                      duration: item.duration,
                    },
                  });
                });
              }
            });
          }
        }
        if (type === 'tasks') {
          const { id } = payload[0];
          switch (action) {
            case 'remove':
              if (id === currentTaskId) {
                store.dispatch({ type: `state/removeCurrentTask`, payload });
              }
              break;
            case 'update': {
              if (currentTaskId === id) {
                store.dispatch({ type: `state/updateCurrentTask`, payload });
              }
              break;
            }
          }
        }
        if (type === 'shifts') {
          const { user_id } = payload[0];

          switch (action) {
            case 'remove':
              if (payload[0] === currentShiftId) {
                store.dispatch({ type: `state/removeCurrentShift`, payload });
              }
              break;
            case 'update': {
              payload[0] = prepareShift(
                { ...payload[0], times: '', isBefore: false, localTime: '' },
                store.getState().companyOptions.options.time_zone,
              );
              store.dispatch({ type: `shifts/updateShifts`, payload });
              if (userId === user_id) {
                if (payload[0].localTime === day) {
                  store.dispatch({ type: `state/updateCurrentShift`, payload });
                } else if (!store.getState()?.state?.currentShift) {
                  store.dispatch({ type: `state/updateCurrentShift`, payload });
                }
              }
              break;
            }
            case 'create':
              payload[0] = prepareShift(
                { ...payload[0], times: '', isBefore: false, localTime: '' },
                store.getState().companyOptions.options.time_zone,
              );
              store.dispatch({ type: `shifts/createShifts`, payload });
              if (userId === user_id) {
                if (payload[0].localTime === day) {
                  store.dispatch({ type: `state/setCurrentShift`, payload });
                } else if (!store.getState()?.state?.currentShift) {
                  store.dispatch({ type: `state/setCurrentShift`, payload });
                }
              }
              break;
          }
        }
      } else {
        console.error('Wrong formatted payload', type, payload);
      }
    });

    centrifuge.connect();
  }
}

export async function centrifugeToken(): Promise<string> {
  const tokens: GetCentrifugeResult = await Api.getCentrifugeToken();

  if (tokens.kind === 'unauthorized') {
    return 'no unauthorized';
  } else {
    if (tokens.kind === 'ok') {
      return tokens.data.token;
    } else {
      return `tokens.kind =' ${tokens.kind}' `;
    }
  }
}
