import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectUserShifts } from '~/redux/selectors/shiftsSelectors';
import classNames from 'classnames';
import { INewShiftModify } from '~/redux/modules/shiftsModule';
import { ShiftPopup } from '~/components/schedule/ShiftPopup';
import moment, { Moment } from 'moment';
import { tableCellClassName, SHIFT_STATUS } from '~/helpers/constants';
import { selectIsUserAtVacation } from '~/redux/selectors/vacationsSelectors';

interface IFC {
  userId: number;
  date: string;
  rowIndex: number;
  rowLength: number;
  timeZone: number;
  dateMoment: Moment;
  isBefore: boolean;
}

const Shift = ({ userId, date, rowIndex, rowLength, timeZone, dateMoment, isBefore }: IFC) => {
  const shift: INewShiftModify | undefined = useSelector(selectUserShifts(userId)).find(
    (shift: INewShiftModify) => shift.localTime === date,
  );
  const isVacation: boolean = useSelector(selectIsUserAtVacation(userId, date));
  const [isShownInfo, setIsShownInfo] = React.useState<boolean>(false);

  let cellClassName = '';
  let cellClassWarn = '';
  let isSoon = false;

  if (shift) {
    const diff = moment(shift.start_time).add(timeZone, 'hours').diff(dateMoment, 'hours');
    isSoon = diff < 12 && shift.type === 1;
    cellClassWarn = isSoon ? tableCellClassName + '_status_warning' : '';
    cellClassName = tableCellClassName + '_status_' + SHIFT_STATUS[shift.type];
  }

  return (
    <>
      <div
        className={classNames(tableCellClassName, cellClassName, cellClassWarn, {
          [`${tableCellClassName}_vacation`]: isVacation,
          [`${tableCellClassName}_empty`]: !shift?.times && !isVacation,
          [`${tableCellClassName}_isBefore`]: shift?.isBefore || isBefore,
        })}
        onClick={() => !isVacation && setIsShownInfo(true)}
      >
        {shift?.times}
      </div>
      {isShownInfo && (
        <ShiftPopup
          isSoon={isSoon}
          isShown={isShownInfo}
          onClose={() => setIsShownInfo(false)}
          workShiftDate={date}
          shift={shift}
          userId={userId}
          rowIndex={rowIndex}
          rowLength={rowLength}
        />
      )}
    </>
  );
};

export default Shift;
