import { IStore } from '~/redux/IStore';
import { createSelector } from 'reselect';
import { IVacation } from 'sb_manufacturing_front_api';
import moment from 'moment';
import { vacationsAdapter } from '~/redux/modules/vacationsModule';

export const vacationsSelector = vacationsAdapter.getSelectors<IStore>(state => state.vacations);

export const selectIsUserAtVacation = (userId: number, date: string) =>
  createSelector(
    (state: IStore) => vacationsSelector.selectAll(state),
    vacations =>
      vacations.some((vacation: IVacation) => {
        const mDate = moment(date);
        return (
          vacation.user_id === userId &&
          mDate.isBefore(vacation.end_date) &&
          mDate.isAfter(vacation.start_date)
        );
      }),
  );

export const selectVacationsAtDate = (date: string) =>
  createSelector(
    (state: IStore) => vacationsSelector.selectAll(state),
    vacations =>
      vacations.filter((vacation: IVacation) => {
        const mDate = moment(date);
        return mDate.isBefore(vacation.end_date) && mDate.isAfter(vacation.start_date);
      }),
  );
