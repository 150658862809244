import Api, {
  INewShiftsList,
  INewTask,
  ITaskCreateForm,
  ITaskUpdateForm,
  IUpdateTaskStatus,
} from 'sb_manufacturing_front_api';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  Dictionary,
  EntityId,
} from '@reduxjs/toolkit';
import { getDayShifts } from './shiftsModule';
import moment from 'moment/moment';
import { FORMAT_MOMENT, TYPE_ROLE } from '~/helpers/constants';
import errorHandler from '~/utils/errorHandler';
import store from '~/redux/configureStore';
import { setCurrentTask } from '~/redux/modules/stateModule';
import { closePopup } from '~/redux/modules/popupModule';

export const tasksAdapter = createEntityAdapter<
  INewTask & { start_date?: string; end_date?: string; duration?: number }
>({
  selectId: task => task.id,
});

export interface ITasksState {
  ids: EntityId[];
  entities: Dictionary<INewTask & { start_date?: string; end_date?: string }>;
}

export const removeTasks = createAsyncThunk('removeTasks', async (taskId: number) => {
  Api.deleteTask(taskId)
    .then(result => {
      if (result.kind === 'ok') {
        if (store.getState().auth.user.role === TYPE_ROLE.SUPERVISOR) {
          store.dispatch(setCurrentTask());
        }
        store.dispatch(closePopup());
        return taskId;
      } else errorHandler(result);
    })
    .catch(result => {
      errorHandler(result);
    });
  return taskId;
});

export const updateTask = createAsyncThunk(
  'updateTask',

  async (input: ITaskUpdateForm) => {
    return await Api.updateTask(input);
  },
);
export const createTask = createAsyncThunk('createTask', async (input: ITaskCreateForm) => {
  return await Api.createTask(input);
});

export const updateTaskStatus = createAsyncThunk(
  'updateTaskStatus',
  async (input: IUpdateTaskStatus) => {
    const response = await Api.updateTaskStatus({ ...input, lng: 0, lat: 0 });

    if (response.kind === 'ok') {
      if (store.getState().auth.user.role === TYPE_ROLE.SUPERVISOR) {
        store.dispatch(setCurrentTask());
      }
      store.dispatch(closePopup());
    } else errorHandler(response);

    return response;
  },
);

const tasksSlice = createSlice({
  name: 'tasks',
  initialState: tasksAdapter.getInitialState(),
  reducers: {
    create: tasksAdapter.setMany,
    update: tasksAdapter.upsertMany,
    remove: tasksAdapter.removeMany,
    clear: tasksAdapter.removeAll,
    add: tasksAdapter.upsertOne,
  },
  extraReducers: builder => {
    builder.addCase(getDayShifts.fulfilled, (state, action) => {
      tasksAdapter.removeAll(state);
      const payload = action.payload as INewShiftsList;
      tasksAdapter.upsertMany(state, payload.tasks);

      payload.timelessTasks.map(timelessTask => {
        if (timelessTask.tasks?.length) {
          timelessTask.tasks.map(task => {
            tasksAdapter.upsertOne(state, {
              ...task,
              start_date: timelessTask?.start_date,
              end_date: moment(timelessTask?.end_date)
                .subtract(1, 'days')
                .format(FORMAT_MOMENT.YYYYMMDD_HHMMSS),
              duration: timelessTask.duration,
            });
          });
        }
      });
    });
    builder.addCase(removeTasks.fulfilled, (state, action) => {
      tasksAdapter.removeOne(state, action.payload);
    });
    builder.addCase(updateTask.fulfilled, (state, action) => {
      const payload = action.payload;
      if (payload === undefined || payload.kind !== 'ok') return;

      tasksAdapter.upsertMany(state, payload.data);
    });
    builder.addCase(createTask.fulfilled, (state, action) => {
      const payload = action.payload;
      if (payload === undefined || payload.kind !== 'ok') return;

      tasksAdapter.upsertMany(state, payload.data);
    });
    builder.addCase(updateTaskStatus.fulfilled, (state, action) => {
      const payload = action.payload;
      if (payload === undefined || payload.kind !== 'ok') return;

      tasksAdapter.upsertMany(state, payload.data);
    });
  },
});

export default tasksSlice.reducer;

export const { create, update, remove, clear } = tasksSlice.actions;
