import { EShiftType } from 'sb_manufacturing_front_api';

export const TYPE_ROLE = {
  SUPERVISOR: 'supervisor',
  DEPARTMENT: 'department',
  VIEW: 'view',
  WORKER: 'worker',
  MANAGER: 'manager',
  ADMIN: 'admin',
};

export const FORMAT_MOMENT = {
  YYYYMMDD_HHMMSS: 'YYYY-MM-DD HH:mm:ss',
  YYYYMMDD: 'YYYY-MM-DD',
  DASH_DDMMYYYY: 'DD-MM-YYYY',
  DASH_YYYYMMDD: 'YYYY-MM-DD',
  DDMMYYYY: 'DD.MM.YYYY',
  DDMMMM: 'DD MMMM',
  DDMMM: 'DD MMM',
  D: 'D',
  DMMMM: 'D MMMM',
  DHMM: 'D:H:mm',
  HHMMSS: 'HH:mm:ss',
  HHMM: 'HH:mm',
  HH: 'HH',
  HMM: 'H:mm',
  MM: 'mm',
  MONTH_MM: 'MM',
};

export const COLORS = {
  DARK: '#333333',
  WHITE: '#FFFFFF',
  GREEN: '#6FCF97',
  RED: '#E35B5B',
  PINK: '#FE8282',
  BLUE: '#2F80ED',
  ORANGE: '#FA9131',
  BLUE_LIGHT: '#8BB8F5',
  GREY: '#A5A7A8',
  GREY100: '#B0B2B3',
  GREY200: '#D2D5D7',
  GREY_LIGHT: '#EFEFEF',
  GREY_LIGHT100: '#F5F9FE',
  GREY_LIGHT200: '#EBF3FE',
  GREY_LIGHT300: '#FAFAFA',
  TRANSPARENT: 'transparent',
  GREEN_WARMER: '#97E7B7',
  RED_WARMER: '#F18585',
  BLUE_WARMER: '#62A0F6',
};

export enum DECLINATION_TYPE {
  HOURS,
  TASKS,
  PERFORMERS,
  MINUTES,
  DAYS,
}

export const tableClassName = 'schedule-table';
export const tableRowClassName = `${tableClassName}__row`;
export const tableColClassName = `${tableClassName}__col`;
export const tableCellClassName = `${tableClassName}__cell`;

export const workingShiftInfo = 'workshift-info';

export const createSchedulePopup = 'createSchedulePopup';

export const SHIFT_STATUS = {
  [EShiftType.DEFAULT]: 'default',
  [EShiftType.ADDITIONAL]: 'partTime',
  [EShiftType.ACCEPTED]: 'default',
  [EShiftType.CANCELED]: 'partTimeCancel',
};

export const SHIFT_STATUS_DESCRIPTION = {
  [EShiftType.DEFAULT]: '',
  [EShiftType.ADDITIONAL]: 'Смена ожидает подтверждения от сотрудника',
  [EShiftType.ACCEPTED]: '',
  [EShiftType.CANCELED]: '',
};

export const PASSWORD = {
  SET: 'set',
  CHANGE: 'change',
  FORCED_CHANGE: 'forced-change',
};

export const PROTOCOL = {
  HTTP: 'http:',
  HTTPS: 'https:',
};

export const INPUT_TYPE = {
  TEXT: 'text',
  PASSWORD: 'password',
};

export const ICON_TYPE = {
  CREATE: 'create',
  FILE: 'file',
  ARROW_PREV: 'arrow_prev',
  ARROW_NEXT: 'arrow_next',
  COPY: 'copy',
  DOWNLOAD: 'download',
  CALENDAR: 'calendar',
  REMOVE: 'remove',
  WORKING_SHIFTS: 'working_shifts',
  PRIORITY: 'priority',
  BACK: 'back',
  REVERT: 'revert',
};

export const TYPE_COLOR = {
  PRIORITY: 'priority',
};

export const BUTTON_TYPE = {
  ICON: 'icon',
  TEXT: 'text',
  link: 'link',
};

export const ALERT = {
  REMOVE_USER_SHIFTS: 'REMOVE_USER_SHIFTS',
  REMOVE_TASK: 'REMOVE_TASK',
  START_TASK: 'START_TASK',
  REMOVE_TASK_TIMELESS: 'REMOVE_TASK_TIMELESS',
  REMOVE_TEMPLATE: 'REMOVE_TEMPLATE',
  DISMISS_USER: 'DISMISS_USER',
  RESTORE_USER: 'RESTORE_USER',
  REMOVE_SHIFT: 'REMOVE_SHIFT',
  CHANGE_SHIFT: 'CHANGE_SHIFT',
  END_SHIFT: 'END_SHIFT',
};

export const ALERT_TYPE = {
  REMOVE: 'REMOVE',
  DISMISS: 'DISMISS',
  RESTORE: 'RESTORE',
  CHANGE: 'CHANGE',
  DEFAULT: 'DEFAULT',
  CLEAR: 'CLEAR',
  FINISH: 'FINISH',
};

export const PLACEHOLDER = {
  NONE: 'Не выбрано',
  SELECT_ZONE: 'Выбрать зону',
  ADD_ZONE: 'Добавить зону',
};

export const NAMES_FIELD = {
  ID: 'id',
  DATE: 'date',
  LOGIN: 'login',
  PASSWORD: 'password',
  PASSWORD_WORKER: 'password-worker',
  OLD_PASSWORD: 'old-password',
  NEW_PASSWORD: 'new-password',
  REPEAT_NEW_PASSWORD: 'repeat-new-password',
  LAST_NAME: 'lastname',
  FIRST_NAME: 'firstname',
  MIDDLE_NAME: 'middlename',
  DEPARTMENTS: 'departments',
  VIEW_DEPARTMENTS: 'view_departments',
  ROLE_NAME: 'role_name',
  SCHEDULE_TEMPLATE_START_DATE: 'schedule_template_start_date',
  SCHEDULE_TEMPLATE_END_DATE: 'schedule_template_end_date',
  PROFESSION_ID: 'professionId',
  ROLE: 'role',
  SCHEDULE_TEMPLATE_ID: 'scheduleTemplateId',
  DEPARTMENT_ID: 'departmentId',
  CATEGORY: 'category',
  PRIORITY: 'priority',
  TYPE_ID: 'type_id',
  GEO_TYPE: 'geo_type',
  GEO: 'geo',
  AS_TEMPLATE: 'as_template',
  IS_REPEAT: 'is_repeat',
  NEED_PHOTO_REPORT: 'need_photo_report',
  IS_COMMENT: 'is_comment',
  NEED_DEPARTMENT_TEST: 'need_department_test',
  NEED_SUPERVISOR_TEST: 'need_supervisor_test',
  NEED_PATH_PHOTO_REPORT: 'need_path_photo_report',
  DESCRIPTION: 'description',
  USERS: 'users',
  ATTACHMENT: 'attachment',
  TITLE: 'title',
  TEMPLATE_ID: 'template_id',
  SCHEDULE_TIME_HOURS: 'schedule_time_hours',
  SCHEDULE_TIME_MINUTES: 'schedule_time_minutes',
  TIME_FROM: 'time_from',
  TIME_TO: 'time_to',
  TASK_ID: 'task_id',
  TASK_START_DATE: 'task_start_date',
  TASK_END_DATE: 'task_end_date',
  UNFIXED: 'unfixed',
};
